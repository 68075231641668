<template>
  <div class="tagManagement">
    <ValidationObserver ref="tagManagementObserver" tag="div" class="section">
      <div class="md-layout-vertical">
        <ValidationProvider rules="required" name="SelectedTagId" v-slot="{ errors, classes }" class="md-layout-item md-size-30" tag="div">
          <md-field v-bind:class="classes" ref="errorMessage">
            <label for="selecttag">{{ $t('tag.chooseTag') }}</label>
            <md-select v-model=selectedTagId name="selecttag" id="selectTag">
              <md-option v-for="(tag, index) in tags"
                         :key="index"
                         :value="tag.TagId">
                {{ tag.Name }}
              </md-option>
            </md-select>
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="md-layout-item">
        <div class="title" ref="title">{{ $t('tag.selectProduct') }}</div>
        <div class="md-layout-item-horizontal md-layout">
          <div class="md-layout-item-vertical">
            <omni-major-category-select v-model="omniIntermediateCategoryId" :vueMaterialStyle="true"></omni-major-category-select>
          </div>
          <div class="md-layout-item-vertical">
            <omni-intermediate-category-select v-model="omniIntermediateCategoryId" :omniMajorCategoryId="omniMajorCategoryId" :vueMaterialStyle="true"></omni-intermediate-category-select>
          </div>
          <div class="md-layout-item-vertical">
            <omni-minor-category-select v-model="omniMinorCategoryId" :omniIntermediateCategoryId="omniIntermediateCategoryId" :vueMaterialStyle="true"></omni-minor-category-select>
          </div>
          <div class="md-layout-item-vertical">
            <enum-select-vue-material :enum="StatusCodeEnum" :selected.sync="statusCode" :label="$t('tag.status')" ressourceName="statusCode" :defaultValue="StatusCodeEnum.None"></enum-select-vue-material>
          </div>
          <div class="md-layout-item">
            <md-button class="md-primary" @click="searchProduct">{{$t('tag.search')}}</md-button>
          </div>
        </div>
      </div>
      <div class="md-layout-item">
        <div>
          <md-table class="productsListingContent" v-model="products" md-card @md-selected="onProductSelect">
            <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select :md-disabled="item.TagIds.includes(selectedTagId)">
              <md-table-cell :md-label="$t('product.sku')">{{ item.Sku }}</md-table-cell>
              <md-table-cell :md-label="$t('product.description')">{{ item.Description }}</md-table-cell>
              <md-table-cell :md-label="$t('tag.categoryType')">{<omni-major-category-text v-model="item.OmniMajorCategoryId"></omni-major-category-text></md-table-cell>
              <md-table-cell :md-label="$t('tag.category')"><omni-intermediate-category-text v-model="item.OmniIntermediateCategoryId"></omni-intermediate-category-text></md-table-cell>
              <md-table-cell :md-label="$t('tag.subCategory')"><omni-minor-category-text v-model="item.OmniMinorCategoryId"></omni-minor-category-text></md-table-cell>
              <md-table-cell :md-label="$t('product.status')">{{ Object.keys(StatusCodeEnum).find(k => StatusCodeEnum[k] === item.StatusCode) }}</md-table-cell>
              <md-table-cell :md-label="$t('tag.tag')">
                [Image] {{ item.TagIds.length }}
                <md-tooltip md-direction="bottom" v-if="item.TagIds.length > 0">
                  <ul>
                    <li v-for="(tagName, tagIndex) in getTagName(item.TagIds)" :key="tagIndex">{{ tagName }}</li>
                  </ul>
                </md-tooltip>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div class="paginationSection">
          <span>{{ $t('tag.selectedProduct') }}: {{ selectedProductCount }}</span>
          <paginate :page-count="pageCount"
                    :click-handler="getAllProducts"
                    :prev-text="$t('app.previous')"
                    :next-text="$t('app.next')"
                    :container-class="'pagination'"
                    :page-class="'page-item'">
          </paginate>
        </div>
        <div v-show="isLoadingProducts">
          <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div class="md-layout-item">
          <md-button class="md-primary" @click="associateProductToTag" :disabled="!hasSelectedProducts">{{$t('tag.associateProductToTag')}}</md-button>
        </div>

        <md-snackbar md-position="center" :md-duration="6000" :md-active.sync="showSnackbar" md-persistent>
          <span>{{$t('tag.productAssociateToTag')}}</span>
        </md-snackbar>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import OmniMajorCategorySelect from '@/components/OmniMajorCategorySelect.vue';
  import OmniMinorCategorySelect from '@/components/OmniMinorCategorySelect.vue';
  import OmniIntermediateCategorySelect from '@/components/OmniIntermediateCategorySelect.vue';
  import OmniMajorCategoryText from '@/components/OmniMajorCategoryText.vue';
  import OmniIntermediateCategoryText from '@/components/OmniIntermediateCategoryText.vue';
  import OmniMinorCategoryText from '@/components/OmniMinorCategoryText.vue';
  import { statusCodeEnumWeb } from '@/models/GeneratedModels/StatusCodeEnumWeb.js';
  import '@/scripts/validation.js';
  import VueScrollTo from 'vue-scrollto';

  export default {
    data: () => ({
      selectedTagId: null,
      omniMajorCategoryId: null,
      omniMinorCategoryId: null,
      omniIntermediateCategoryId: null,
      statusCode: null,
      StatusCodeEnum: statusCodeEnumWeb,
      productPerPage: 20,
      currentPage: 1,
      selectedProducts: [],
      associationStartDate: null,
      associationEndDate: null,
      showSnackbar: false
    }),
    components: {
      OmniMajorCategorySelect,
      OmniMinorCategorySelect,
      OmniIntermediateCategorySelect,
      OmniMajorCategoryText,
      OmniIntermediateCategoryText,
      OmniMinorCategoryText
    },
    created: function () {
      this.$store.dispatch('tag/GetTags');
    },
    computed: {
      tags() {
        return this.$store.state.tag.tags;
      },
      products() {
        return this.$store.state.tag.activeProducts;
      },
      productCount() {
        return this.$store.state.tag.productCount;
      },
      isLoadingProducts() {
        return this.$store.state.tag.isLoading;
      },
      selectedProductCount() {
        return this.selectedProducts.length;
      },
      hasSelectedProducts() {
        return this.selectedProductCount > 0;
      },
      pageCount() {
        return Math.ceil(this.productCount / this.productPerPage);
      }
    },
    methods: {
      onProductSelect: function (products) {
        this.selectedProducts = products;
      },
      searchProduct: function () {
        this.getAllProducts(1);
      },
      getAllProducts(pageNumber) {
        this.currentPage = pageNumber;
        this.$store.dispatch('tag/GetProductWithTags', [pageNumber, this.productPerPage, this.omniMajorCategoryId, this.omniIntermediateCategoryId, this.omniMinorCategoryId, this.statusCode]);
      },
      getTagName(tagIds) {
        if (tagIds) {
          return tagIds.map(tagId => {
            let tagFound = this.tags.find(tag => tag.TagId === tagId);
            return tagFound ? tagFound.Name : 'Introuvable';
          });
        }
        return '';
      },
      associateProductToTag() {
        this.$refs.tagManagementObserver.validate().then((isSuccess) => {
          if (isSuccess) {
            this.$store.dispatch('tag/AssociateProductToTag', [this.selectedProducts.map(x => x.ProductId), this.selectedTagId, this.associationStartDate, this.associationEndDate]);
          }
          else {
            VueScrollTo.scrollTo(this.$refs.errorMessage, {
              container: 'body',
              duration: 250,
              offset: -50,
              cancelable: false
            });
          }
        });
      }
    },
    watch: {
      '$store.state.tag.isAssociateProductToTagSuccess': function (val) {
        if (val.isSuccess) {
          this.selectedProducts = [];
          this.getAllProducts(this.currentPage);

          VueScrollTo.scrollTo(this.$refs.title, {
              container: 'body',
              duration: 250,
              offset: -50,
              cancelable: false
          });

          this.showSnackbar = true;
        }
      }
    }
  };
</script>

<style lang="scss">
  .md-tooltip {
    height: initial;
    font-size: initial;
    white-space: initial;
  }

  .md-error {
    color: #ff1744
  }
  .tagManagement .section{
    margin-bottom: 1.50rem;
    padding: 1.50rem 3.00rem;
  }
  .tagManagement .md-layout-vertical{
    padding-bottom:1.00rem;
  }

  .tagManagement .md-layout-item .title{
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    color: #000;
    padding-bottom:1.00rem;
  }
  .tagManagement .md-layout-item-vertical{
    margin-right:1.00rem;
  }

  .tagManagement .md-field.md-theme-default label{
    color: rgba(0,0,0,0.87);
  }

  .paginationSection{
    padding-top:1.00rem;
  }
  .paginationSection .pagination{
    padding:20px 0;
  }

  .pagination {
  display: inline-block;
  padding-left: 0;
  padding: 20px;
  border-radius: 4px;
  width:100%;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height:1.42857143;
      text-decoration: none;
      color:#fff;
      background-color:#fff;
      border: 1px solid #ddd;
      margin-left: -1px;
    }
    &:first-child {
      > a,
      > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      }
    }
    &:last-child {
      > a,
      > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
    z-index: 3;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
    text-decoration:none;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
    }
  }
}
</style>
